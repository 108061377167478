import {makeStyles} from "@material-ui/core/styles";
import colorsScheme from "../../settings/colorScheme";

const theme = (props) => {
    const styles = makeStyles(theme => ({
        stepLabel: {
            marginBottom: 20,
            color: "#707070 !important"
        },
        selectedProgramWrapper: {
            backgroundColor: "#315da8",
            padding: 15,
            borderRadius: 3,
            marginBottom: 20,
            border: `1px solid ${colorsScheme.lowLightGrey}`,
            boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.16)",
            cursor: "pointer"
        },
        programWrapper: {
            backgroundColor: colorsScheme.white,
            padding: 15,
            borderRadius: 3,
            marginBottom: 20,
            border: `1px solid ${colorsScheme.lowLightGrey}`,
            boxShadow: "3px 3px 6px #00000029",
            cursor: "pointer"
        },
        programSubtextWrap: {
            display: "flex",
            marginBottom: 10
        },
        selectedProgramText: {
            color: colorsScheme.background,
            fontWeight: "bold"
        },
        programText: {
            color: colorsScheme.highDarkGrey,
            fontWeight: "bold"
        },
        selectedProgramSubtext: {
            color: colorsScheme.background
        },
        programSubtext: {
            color: "#808080"
        },
        selectedInfoIcon: {
            fill: colorsScheme.white,
            width: 22,
            height: 22,
            cursor: "pointer"
        },
        infoIcon: {
            fill: "#808080",
            width: 22,
            height: 22,
            cursor: "pointer"
        },
        descriptionLabel: {
            color: `${colorsScheme.orange} !important`,
            marginBottom: 15
        },
        selectedCheckIcon: {
            fill: `${colorsScheme.white} !important`
        },
        checkIcon: {
            fill: `${colorsScheme.highLightGrey} !important`
        },
        textFieldWrapper: {
            paddingBottom: "5px !important",
            paddingTop: "5px !important"
        },
        textFieldLabel: {
            '& .title': {
                color: "#707070 !important"
            }
        },
        reserveButton: {
            marginTop: 30,
            borderRadius: "3px !important",
            padding: "20px !important",
            width: 200
        },
        infoText: {
            color: "#707070 !important"
        },
        contactsWrapper: {
            marginTop: 10
        },
        infoGrid: {
            marginTop: 20,
            marginBottom: 25
        },
        instructionsText: {
            color: "#707070 !important"
        },
        selectDateButton: {
            borderRadius: "3px !important",
            padding: "18px !important",

            '& svg': {
                width: 20,
                height: 20
            }
        },
        notBookableEvent: {
            backgroundColor: "#EFEFEF",
            color: "#EFEFEF",
            border: "none !important",
            borderRadius: 0,
            cursor: "default",
            height: "100% !important",
            width: "calc(100% + 10px) !important",
            left: "0% !important",
            pointerEvents: "none",

            '& .rbc-event-content': {
                color: '#808080',
                textAlign: 'center'
            }
        },
        notBookableEventFriday: {
            backgroundColor: "#EFEFEF",
            color: "#EFEFEF",
            border: "none !important",
            borderRadius: 0,
            cursor: "default",
            height: "44% !important",
            width: "calc(100% + 10px) !important",
            left: "0% !important",
            pointerEvents: "none",

            "& .rbc-event-content": {
                color: "#808080",
                textAlign: "center"
            }
        },
        normalEvent: {
            backgroundColor: "#aaa",
            border: "none !important",
            cursor: "default",
            pointerEvents: "none"
        },
        calendarGrid: {
            marginBottom: 30,
            marginTop: 30
        },
        errorCalendarGrid: {
            marginBottom: 30,
            marginTop: 30,
            border: `3px solid ${colorsScheme.darkOrange}`
        },
        datePickerTextField: {
            display: "none"
        },
        dialogContainer: {
            maxWidth: 300,
            padding: "15px 20px"
        },
        dialogTitle: {
            fontWeight: "bold",
            color: colorsScheme.orange,
            fontSize: 18
        },
        otherTimeButtonWrap: {
            textAlign: "right",
            cursor: "pointer",
            color: "#707070",
            fontWeight: "bold"
        },
        confirmDatesButtonWrap: {
            textAlign: "center",
            marginTop: 25
        },
        datesText: {
            color: "#707070"
        },
        starTimeText: {
            margin: "5px 0px"
        },
        completeText: {
            color: "#707070"
        },
        disabledImageWrapper: {
            textAlign: "center"
        },
        slotNotFreeWrap: {
            color: "#B92D00",
            marginTop: 7,
            marginBottom: 10
        },
        accessibilityGrid: {
            padding: "15px 15px 10px 10px",
            border: "1px solid #888",
            borderRadius: "4px",
            marginBottom: "20px",
            marginTop: 40,
            display: "flex",
            '@media (max-width: 540px)': {
                paddingLeft: 5
            },
        },
        accessibilityImg: {
            float: "left",
            paddingRight: 5,
        },
        accessibilityText: {
            color: "#111111",
            fontWeight: 400
        },
        privacyButton: {
            width: 18,
            cursor: "pointer",
            marginLeft: 3
        },
        stepTwoContainer: {
            '@media (max-width: 540px)': {
                marginTop: 40
            },
            '@media (min-width: 541px)': {
                marginTop: 20
            }
        },
        accessibilityImgWrapper: {
            alignSelf: "center"
        },
        extraClass: {
            cursor: "pointer",
            width: 18,
            position: "relative",
            top: "7px",
            marginLeft: "5px",
        },
        gdprText: {
            color: "#707070 !important",
            lineHeight: "0px",
            '@media (min-width: 541px)': {
                marginTop: "-15px"
            },
        },
        errorText: {
            color: "#B92D00",
            marginLeft: 10
        }
    }));
    return styles();
}
export default theme;
