import React, {useState, useEffect} from "react";
import {Dialog, Grid, Typography} from "@material-ui/core";
import {MuiPickersUtilsProvider, DateTimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {Calendar, momentLocalizer} from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import clsx from 'clsx';
import {useSelector} from "react-redux";
import SmallTitle from "../../../components/DataDisplay/SmallTitle";
import translate from "../../../functions/applyTranslation";
import theme from "../style";
import Button from "../../../components/Button";
import NOT_BOOKABLE_DATES from "../../../functions/notBookableDates";

moment.locale("sv");

const localizer = momentLocalizer(moment);

const messages = {
    today: 'Idag',
    previous: 'Tillbaka',
    next: 'Nästa',
};

const Step2 = props => {
    const classes = theme();

    const {error} = useSelector(state => state.states);

    const [pickerOpen, setPickerOpen] = useState(false);
    const [notBookableEvents, setNotBookableEvents] = useState([]);
    const [dayView, setDayView] = useState(new Date());
    const [graceError, setGraceError] = useState(false);

    const {
      data,
      handleDateChange,
      dialogOpen,
      setDialogOpen,
      events,
      createEvent,
      errorCalendar,
      tempEvent,
      checkSlot,
      freeSlot,
      setDatesRange,
    } = props;

    const customEvent = event => {
      return {
        className: event.notBookable ? (event.friday || event.halfDay ? classes.notBookableEventFriday : classes.notBookableEvent) : (event.tempEvent ? "" : classes.normalEvent)
      }
    }

/*    const createNonBookableDate = (startDate, endDate) => {
      let event = {
        title: "",
        start: startDate.toDate(),
        end: endDate.toDate(),
        notBookable: true,
        halfDay: (moment(startDate).hour() === 12 && moment(startDate).minute() === 0 && moment(startDate).second() === 0) || (moment(endDate).hour() === 12 && moment(endDate).minute() === 0 && moment(endDate).second() === 0)
      };

      setNotBookableEvents(prevState => [...prevState, event]);
    }*/

    const createNonBookableEvent = (dates) => {
      const friday = dates[4] || dates;

      const day = friday.getDate();
      const month = friday.getMonth();
      const year = friday.getFullYear();
      const eventStart = new Date(year, month, day, 13, 30);
      const eventEnd = new Date(year, month, day, 17, 30);

      //Add first friday when calendar loads
      if (notBookableEvents.length === 0) {
        let _notBookableEvents = [];

        const event = {
          title: "Stängt för Shabbat",
          start: eventStart,
          end: eventEnd,
          notBookable: true,
          friday: true
        }
        _notBookableEvents.push(event);
        setNotBookableEvents(_notBookableEvents);
      } else {
        if (notBookableEvents.filter(item => item.start.getDate() === day && item.start.getMonth() === month && item.start.getFullYear() === year).length === 0) {
          const event = {
            title: "Stängt för Shabbat",
            start: eventStart,
            end: eventEnd,
            notBookable: true,
            friday: true
          }
          setNotBookableEvents([...notBookableEvents, event]);
        }
      }
    }

    const handleEventClick = e => {
      if (tempEvent) {
        if (tempEvent.end === e.end && tempEvent.start === e.start) {
          setDialogOpen(true)
        }
      }
    }

    const updateDatesRange = dates => {
      let monday = dates[0];
      let friday = moment(dates[4]).endOf('day').toDate();
      setDatesRange([monday, friday]);
    }

    useEffect(() => {
      let firstFriday = moment(new Date()).endOf('week').subtract(2, 'days').toDate();
      createNonBookableEvent(firstFriday);

/*      for (let i in NOT_BOOKABLE_DATES) {
        let date = NOT_BOOKABLE_DATES[i];
        createNonBookableDate(date.startDate, date.endDate);
      }*/
    }, []);

    useEffect(() => {
      if (error)
        setGraceError(error.error === 'api..minimum_graceperiod_not_met');
      else {
        setGraceError(false);
      }
    }, [error])

    return (
      <Grid container className={classes.stepTwoContainer}>
        <Grid item xs={12} className={classes.stepLabel}>
          <SmallTitle text={translate('booking', 'step2_label')} className={classes.instructionsText}/>
          <Typography variant="subtitle2" className={classes.instructionsText}>
            {translate('booking', 'step2_instructions_text')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Button
              fill
              label={translate('booking', 'select_date_button')}
              icon='calendar'
              className={classes.selectDateButton}
              onClick={() => setPickerOpen(true)}
            />
            <DateTimePicker
              open={pickerOpen}
              className={classes.datePickerTextField}
              ampm={false}
              value={data.startDate}
              disablePast={true}
              format="YYYY-MM-DD HH:mm"
              id="date"
              onClose={() => {
                setPickerOpen(false)
              }}
              onChange={(e) => {
                setDayView(moment(e).toDate())
                handleDateChange(e);
                setPickerOpen(false);
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={8} className={errorCalendar ? classes.errorCalendarGrid : classes.calendarGrid}>
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          date={dayView}
          defaultView="work_week"
          views={['work_week']}
          events={[...events, ...notBookableEvents, tempEvent]}
          step={30}
          eventPropGetter={customEvent}
          onSelectEvent={handleEventClick}
          style={{height: "500px"}}
          min={new Date(2019, 0, 1, 8, 30)} // 8:30 AM
          max={new Date(2020, 0, 1, 16, 30)} // 4:30 PM
          onRangeChange={(dates) => {
            setDayView(moment(dates[0]).toDate());
            createNonBookableEvent(dates);
            updateDatesRange(dates);
          }}
          messages={messages}
        />
          <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
            <Grid container alignItems="center" justify="space-between" className={classes.dialogContainer}>
              <Grid item xs={6}>
                <Typography variant="subtitle1"
                            className={classes.dialogTitle}>{translate("booking", "selected_time_modal")}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.otherTimeButtonWrap}>
                <Typography variant="body2" onClick={() => {
                  setDialogOpen(false);
                  setPickerOpen(true);
                }}>
                  {translate("booking", "other_time_modal")}
                </Typography>
              </Grid>
              {(!freeSlot || graceError) &&
              <Grid item xs={12} className={classes.slotNotFreeWrap}>
                {graceError ?
                  <Typography variant="body1"><b>{translate("booking", "minimum_grace_error")}</b></Typography>
                  :
                  <Typography variant="body1"><b>{translate("booking", "occupied_time")}</b></Typography>
                }
              </Grid>
              }
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  className={classes.datesText}>{translate("booking", "confirm_date")}: {moment(data.startDate).format('D MMMM YYYY')}</Typography>
                <Typography
                  variant="body2"
                  className={clsx(classes.starTimeText, classes.datesText)}>{translate("booking", "confirm_start_time")}: {moment(data.startDate).format('HH:mm')}</Typography>
                <Typography
                  variant="body2"
                  className={classes.datesText}>{translate("booking", "confirm_end_time")}: {moment(data.endDate).format('HH:mm')}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.confirmDatesButtonWrap}>
                <Button
                  fill
                  fullWidth
                  disabled={!freeSlot}
                  label={translate('booking', 'confirm_date_button')}
                  className={classes.selectDateButton}
                  onClick={() => {
                    setDialogOpen(false);
                    createEvent();
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Grid>
      </Grid>
    );
  }
;

export default Step2;